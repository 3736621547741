<template>
  <div class="card">
    <div class="box widget-social nice-font pt-2 pb-2" :class="componentStyle">
      <div v-if="isLoading">
          <loader-skeleton></loader-skeleton>
      </div>
      <div class="menu-overlay">
        <Button v-if="paramChangeState" icon="pi mif-wrench" :class="'p-button-rounded p-link mr-1 mt-1 fg-lightAmber'" @click="multiParamChange" v-tooltip="'Werte ändern'"></Button>
        <Button v-if="!isEditMode" icon="pi pi-sync" :class="'p-button-rounded p-link mr-1 mt-1 fg-lightAmber' + isLoadingCss" @click="loadParameterValues" v-tooltip="'Aktualisieren'"></Button>
        <Button v-if="isEditMode" icon="pi pi-bars" class="p-button-rounded p-link mr-1 mt-1 fg-lightAmber handle" title="Bewegen"></Button>
        <Button icon="pi pi-ellipsis-h" class="p-button-rounded p-link mr-1 mt-1 fg-lightAmber" @click="toggle" v-tooltip="'Menü'"></Button>
        <Menu :id="'boxMenu_' + 1" ref="menu" :model="items" :popup="true" :baseZIndex="100" />
      </div>
      <div v-if="!isLoading">
        <div class="info flex flex-column mb-2 fg-lime">
          <!-- <p class="text-left"><i class="mif-table"></i><span class="pl-1 mt-1">{{ titleName }}</span></p> -->
          <p class="text-left"><i class="mif-table"></i><span class="pl-1 mt-1">{{ titleName }}</span><span class="pl-1 mt-1 fg-lightPink">{{ paramLabel }}</span></p>
        </div>
        <DataTable 
          ref="dt"
          :value="getModuleValues(nodeData)"
          v-model:selection="selectedParameters"
          dataKey="identifier" 
          v-model:editingRows="editingRows"
          :paginator="false"
          :rows="100"
          selectionMode="multiple"
          class="p-datatable-sm editable-cells-table"
          @row-dblclick="multiParamChange"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[10, 50, 100]"
          currentPageReportTemplate="Zeige {first} bis {last} von {totalRecords} Einträgen"
          responsiveLayout="scroll"
        >
          
          <template #loading> Daten werden geladen... bitte warten </template>

          <Column selectionMode="multiple" :exportable="false" style="width:5%"></Column>
          <Column field="department" header="Anlage" :sortable="true">
            <template #body="slotProps">
              {{ slotProps.data.department }} - {{ slotProps.data.moduleLabel }}
            </template>
          </Column>
          <Column field="label" header="Bezeichnung" :sortable="false">
            <template #body="slotProps">
              {{ `${slotProps.data.index}` }}{{ slotProps.data.label }}
            </template>
          </Column>
          <Column field="unit" header="Einheit" :sortable="false">
            <template #body="slotProps">
              {{ slotProps.data.unitStr }}
            </template>
          </Column>
          <Column field="value" header="Wert" :sortable="false">
            <template #editor="{ data, field }">
              <!-- <InputText v-model="data[field]" autofocus />{{ data.unit }} -->
              <InputText v-model="data[field]" class="nice-font p-0 m-0 inputtext-param text-center" />
            </template>
            <template #body="slotProps">
              <!-- data object instead of slotProps.data.value because of paramValue() transformation -->
              <transition name="valchange" mode="out-in">
                <p :key="slotProps.data.value" class="cursor-pointer">{{ formatValue(slotProps.data) }}</p>
              </transition>
            </template>
          </Column>
        </DataTable>
      </div>

      <div v-if="entryDialog === true">
        <dialog-parameter-multi v-bind:showDialog="entryDialog" :dialogObj="ioObj" v-bind:dialogData="ioDialogData"
          @entrySave="
            ($value) => {
              changeVal($value);
            }
          " @entryAbort="hideDialog()"></dialog-parameter-multi>
      </div>
    </div>
  </div>

  <Dialog v-model:visible="diagMultiChange" :closable="false" :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
    :style="{ width: '450px' }" :modal="true" class="p-fluid">
    <template #header>
      <p class="nice-font"><i class="mif-wrench"></i><span class="pl-1 mt-1">MultiChange</span><span class="pl-1 mt-1 fg-lightPink">⚡ {{ paramLabel }}</span></p>
    </template>
    <div @keydown.esc="hideMultiChange" @keydown.enter="saveMultiChange">
      <Panel>
        <div class="flex pb-3 justify-content-center">
          <Avatar v-for="param in selectedParameters" :key="param.identifier" :item="param" :label="param.department" v-tooltip="`${param.moduleLabel} - ${param.moduleDescription}`" class="mr-1 font-semibold bg-lightLime fg-crimson cursor-pointer" />
        </div>
        <div class="grid p-fluid">
          <div class="col-12 pl-5 pr-5">
            <div v-if="changingParameters[0].unit === '#BOOL'" class="text-center mt-2 mb-2">
              <InputSwitch v-model="changingParameters[0].value" /><span class="ml-2">Ein/Aus</span>
            </div>
            <div v-else-if="changingParameters[0].unit === '#HMS'" class="p-inputgroup">
              <InputMask id="changingValueField" ref="changingValueField" mask="99:99:99" placeholder=""
                slotChar="hh:mm:ss" v-model="changingParameters[0].value" autofocus @focus="focusElement"
                class="nice-font inputtext-param text-center text-5xl" />
                <span class="p-inputgroup-addon nice-font text-3xl">{{ changingParameters[0].unitStr }}</span>
            </div>
            <div v-else class="p-inputgroup">
              <InputNumber id="changingValueField" ref="changingValueField"
                v-model="changingParameters[0].value" autofocus @focus="focusElement"
                :minFractionDigits="1" mode="decimal" locale="de-DE" :useGrouping="false"
                inputClass="nice-font inputtext-param text-center text-5xl"  />
              <!-- <InputText v-model="changingValue" :ref="'e' + 'param.index'" class="nice-font inputtext-param text-center text-5xl"
              @focus="focusField('e' + 'param.index')" @blur="blurField" /> -->
              <span class="p-inputgroup-addon nice-font text-3xl">{{ changingParameters[0].unitStr }}</span>
            </div>
          </div>
        </div>
      </Panel>
    </div>
    <template #footer>
      <Button label="Abbrechen" icon="pi pi-times" class="p-button-text" @click="hideMultiChange" />
      <Button label="Speichern" icon="pi pi-check" class="p-button-text" @click="saveMultiChange" />
    </template>
  </Dialog>
</template>

<script>
import { defineComponent } from "vue";
import store from "@/store";
import { mapGetters, mapActions } from 'vuex';
import SocketioService from "@/services/socketioService.js";
import helpers from "@/helpers";
import { parseNode, setOpcValueToast } from "@/helpers";
// import _ from 'lodash';

export default defineComponent({
  name: "widgetPanelParameter",
  setup() { },
  emits: ["entrySave", "entryDuplicate", "entryDelete"],
  props: {
    content: {
      type: null,
      required: false,
    },
    dragObj: {
      type: null,
      required: false,
    },
    valueObj: {
      type: null,
      required: true,
    },
    dataObj: {
      type: null,
      required: false,
    },
    styleObj: {
      type: null,
      required: false,
    },
    createdLayout: {
        type: Boolean,
        required: false,
    },
    readyLayout: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    // nodeEntry: {},
  },
  watch: {
    createdLayout() {
        this.watchCreatedLayout();
    },
    readyLayout() {
      this.watchLayout();
    },
    '$store.getters.getPageData.editMode': function () {
      this.checkEditMode();
    },
  },
  data() {
    return {
      loading: false,
      nodeData: [],
      nodeDataOld: [],
      selectedParameters: [],
      changingParameters: [],
      changingValue: null,
      changingUnit: null,
      editingRows: [],
      entryDialog: false,
      ioDialogData: {},
      nodes: [],
      ioObj: [],
      paramFiltered: [],
      paramEntry: {
        label: null,
        unit: null,
      },
      node: null,
      map: null,
      diagMultiChange: false,
      config_io: null,
      editField: '',
      items: [],
      items1: [
        {
          label: 'Markierte Werte ändern',
          icon: 'mif-wrench',
          visible: () => this.paramChangeState,
          command: () => {
            this.multiParamChange();
          },
        },
        {
          label: 'Werte-Auswahl',
          icon: 'pi pi-cog',
          command: () => {
            // this.editEntry(this.content);
            this.editEntry(this.dataObj);
          },
        }
      ],
      items2: [
        {
          separator: true,
        },
        {
          label: 'Einstellungen',
          icon: 'pi pi-cog',
          command: () => {
            this.editEntry(this.dataObj);
          },
        },
        {
          label: 'Duplizieren',
          icon: 'pi pi-clone',
          command: () => {
            this.duplicateEntry();
          },
        },
        {
          label: 'Löschen',
          icon: 'pi pi-times',
          command: () => {
            this.deleteEntry();
          },
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      editMode: 'getEditMode',
      getNodeWidget: 'getNodeWidget',
      getOneDepartment: 'types/getOneDepartment',
      getOneCategory: 'types/getOneCategory',
      getStyleColors: 'getStyleColors',
      getStylePatterns: 'getStylePatterns',
      getModules: 'opcua/getModules',
      getModule: 'opcua/getModule',
      getNode: 'opcua/getNode',
      getModuleFetched: 'opcua/getModuleFetched',
      isMaster: 'auth/isMaster',
    }),
    isLoading: function() {
        if (this.loading) return true;
        return false;
    },
    // componentStyle: function () {
    //     if (this.content.style && this.content.style !== 'standard') {
    //         return 'box ptrn-' +  this.content.style.pattern + ' bg-' + this.content.style.background + ' fg-' + this.content.style.foreground;
    //     } else {
    //         return '';
    //     }
    // },
    componentStyle: function () {
        if (this.content.style && this.content.style !== 'standard') {
            let background = `bg-${this.content.style.background}`;
            return `box ptrn-${this.content.style.pattern} ${background} fg-${this.content.style.foreground}`;
        } else {
            return '';
        }
    },
    isEditMode: function () {
      if(this.$store.getters.getPageData.editMode) {
        return true;
      } else {
        return false;
      }
    },
    isLoadingCss: function () {
      if (this.loading) {
          return ' pi-spin';
      } else {
          return ' p-button-plain';
      }
    },
    paramChangeState() {
      // console.log(this.selectedParameters);
      if (this.selectedParameters.length > 0 && !this.isEditMode) {
        return true;
      } else {
        return false;
      }
    },
    titleName: function () {
      if (this.dragObj.label === "") {
        if (this.dataObj[0] && this.dataObj[0].children) {
          return this.dataObj[0].children.key + ' ⚡';
        } else {
          return "";
        }
      } else {
        return this.dragObj.label + ' ⚡';
      }
    },
    paramLabel: function () {
      if (this.nodeData[0] !== undefined && this.nodeData[0].description) {
        return this.nodeData[0].description;
      } else {
        return this.$store.getters.getNoval;
      }
    },
    moduleHash: function () {
      if (this.content.data && this.content.data.displayName) {
        return `*${this.content.data.displayName}*`;
      }
      return null;
    },
  },
  created() {
    // if (!this.getModuleFetched(this.moduleHash)) {
      // const moduleHash = `${module.source.server}@${module.source.identifier}.*`;

    // } else {
    //     this.loading = false;
    // }
    this.loadParameterValues();
  },
  mounted() {
    this.checkEditMode();
  },
  methods: {
    ...mapActions({
        loadNode: 'opcua/loadNode',
        loadModule: 'opcua/loadModule',
        setNodesFull: 'opcua/setNodesFull',
        setModuleFetched: 'opcua/setModuleFetched',
    }),
    loadParameterValues() {
      this.loading = true;
      const moduleHash = this.moduleHash;
      SocketioService.getRedisNodesParam(moduleHash, (err, response) => {
        if (!err && response) {
          if (Array.isArray(response) && response.length > 0) {
            // this.setNodesFull(response);
            let tmpNodes = [];
            let tmpNodesFilter = [];
            tmpNodes = JSON.parse(JSON.stringify(response));
            tmpNodesFilter = tmpNodes.filter(item => item.moduleScheme === this.content.data.moduleScheme && item.displayName === this.content.data.displayName);
            this.setNodesFull(tmpNodesFilter);
            this.nodeData = [ ...tmpNodesFilter ];
            // this.nodeDataOld = JSON.parse(JSON.stringify(this.nodeData));
            // console.log(this.nodeData);
          }
        }
      }).then(() => { 
          if (this.editMode) console.log(`[ENATWIDGET] ${this.moduleHash} : panelparameter ready!`);
          this.setModuleFetched(this.moduleHash);
          this.loading = false;
      });
    },
    getModuleValue(schemeEntry) {
        const key = `${this.moduleHash}.${schemeEntry}`;
        return this.getNode(key);
    },
    getModuleValueStr(schemeEntry) {
        const key = `${this.moduleHash}.${schemeEntry}`;
        const node = this.getNode(key);
        if (typeof node.unit === 'string' && node.unit.length > 0) {
            return `${node.value}${node.unit}`;
        }
        return `${node.value}`;
    },
    getModuleValues(schemeEntries) {
      if (Array.isArray(schemeEntries) && schemeEntries.length > 0) {
        let entries = [];
        schemeEntries.forEach((entry) => {
          // console.log(entry);
          const key = `${entry.server}@${entry.identifier}`;
          const node = this.getNode(key);
          if (node) {
            if (node.child0) {
              try {
                const child = JSON.parse(node.child0);
                if (child.index >= 0) {
                  node.index = (child.index+1).toString();
                } else node.index = '';
              } catch {
                node.index = '';
              }
            } else {
              node.index = '';
            }
            entries.push(node);
          }
        });
        if (entries.length > 0) {
          entries.sort((a, b) => a.department.localeCompare(b.department));
          // console.log(entries);
          return entries;
        }
      }
        return null;
    },
    formatValue(node) {
      if (node.unit === '#BOOL') {
        if (node.value === true) return '🟢 Ein';
        else return '🔴 Aus';
      }
      else return node.value;
    },
    watchCreatedLayout() {
      // // this.loading = true;
      // this.loading = true;
      // if (this.content.data && this.content.data.displayName) {
      //   SocketioService.getRedisNodesParam(`*${this.content.data.displayName}*`, (err, response) => {
      //     if (err || !response) {
      //         console.log(`[ENATWIDGET] error fetching params: ${err}`);
      //     }
      //     else {
      //         if (Array.isArray(response) && response.length > 0) {
      //             let tmpNodes = [];
      //             let tmpNodesFilter = [];
      //             tmpNodes = JSON.parse(JSON.stringify(response));
      //             tmpNodesFilter = tmpNodes.filter(item => item.moduleScheme === this.content.data.moduleScheme && item.displayName === this.content.data.displayName);
      //             this.setNodesFull(tmpNodesFilter);
      //             this.nodeData = [ ...tmpNodesFilter ];
      //             // this.nodeDataOld = JSON.parse(JSON.stringify(this.nodeData));
      //             // console.log(this.nodeData);
      //         }
      //     }
      //   }).then(() => {
      //       this.loading = false;
      //   }); 
      // }
    },
    watchLayout() {
    },
    checkEditMode() {
      if (this.$store.getters.getPageData.editMode) {
        this.items = [...this.items1, ...this.items2];
      } else {
        this.items = [...this.items1];
      }
    },
    loadMap() {
      if (this.dataObj) {
        const map = new Map(store.getters.getMapValues);
        let map2 = new Map();
        let metadata = null;
        let displayNameArrPos = null;
        console.log(">>> DATAOBJ:", this.dataObj);
        this.dataObj.forEach((parameter) => {
          console.log(">>> DATAOBJ_ELM:", parameter.children);
          for (let [key, element] of map) {
            // if (element.key === parameter.children.key) {
            displayNameArrPos = element.key.indexOf('[');
            if (displayNameArrPos === -1) {
              if (element.key === parameter.children.key) {
                console.log(">>> FOUND DATAOBJ:", key, element);
                // metadata = store.getters.getValue(element.id).metadata;
                metadata = store.getters.getValue(element.parent).metadata;
                console.log(">>> METADATA:", metadata);
                // map2.set(element.uuid, element);
                map2.set(element.uuid, this.getMapValue(element.uuid));
              }
            } else {
              for (let i=0; i<8; i++) {
                const index = i.toString();
                const readIndex = (i+1).toString();
                const newKey = [parameter.children.key.slice(0, displayNameArrPos+1), index, parameter.children.key.slice(displayNameArrPos+1)].join('');
                if (element.key === newKey) {
                  // metadata = store.getters.getValue(element.id).metadata;
                  metadata = store.getters.getValue(element.parent).metadata;
                  console.log(">>> METADATA:", metadata);
                  // map2.set(element.uuid, element);
                  let storeMap = { ...this.getMapValue(element.uuid)};
                  let description = "[" + readIndex + "] " + storeMap.description;
                  storeMap.description = description;
                  map2.set(element.uuid, storeMap);
                }
              }
            }
            
          }
          const map3 = new Map([...map2].sort((a, b) => a[1].department.localeCompare(b[1].department)));
          console.log(">>> MAP2 (unsorted):", map2);
          console.log(">>> MAP3 (sorted):", map3);
          // this.map = new Map(map3);
          this.map = new Map(map3);
        });
      }
    },
    updateMap() {
      if (this.node && this.node.size > 0) {
        for (let [key, element] of this.node) {
            const child = store.getters.getMapValue(element.uuid);
            this.node.set(key, {...child, value: child.value});
        }
      }
    },
    currentMap() {
      let map = new Map();
      if (this.node && this.node.size > 0) {
        for (let [key, element] of this.node) {
            const child = store.getters.getMapValue(element.uuid);
            map.set(key, {...child, value: child.value});
        }
        return map;
      }
      return null;
    },
    updateMapValues() {
      for (let [key, element] of this.map) {
        store.getters.getMapValue(element.uuid);
        console.log(">>> UPDATE:", key, element);
      }
    },
    getParameter(lvl1, lvl2, index) {
        let parameter = null;
        if (!lvl1) return false;
        if (!lvl2) lvl2 = '';
        if (!isNaN(index)) {
            parameter = this.node.get(lvl1 + '[' + index + '].' + lvl2);
        } else {
            parameter = this.node.get(lvl1 + lvl2);
        }
        return parameter;
    },
    toggle(event) {
      this.$refs.menu.toggle(event);
      // this.getParameterStatusIOTree();
      // this.getOPCAppTree();
    },
    hideDialog() {
      this.entryDialog = false;
      this.submitted = false;
      // clearInterval(this.intval_Value);
      // this.getUserList();
    },
    hideMultiChange() {
      this.selectedParameters = [];
      this.diagMultiChange = false;
    },
    saveMultiChange() {
      if (this.selectedParameters.length > 0 && this.changingParameters.length > 0) {
        const saveArray = [];
        let tempArray = JSON.parse(JSON.stringify(this.selectedParameters));
        if (Array.isArray(tempArray) && tempArray.length > 0) {
          tempArray.forEach((entry) => {
            if (entry.value !== this.changingParameters[0].value) {
              entry.value = this.changingParameters[0].value;
              saveArray.push(parseNode(entry, true));
            }
          });
          if (saveArray.length > 0) {
            SocketioService.setOpcValue(saveArray, (err, response) => {
              if (err) {
                  console.log(err);
              } else {
                  console.log(response);
                  setOpcValueToast(response, this.$root);
                  this.selectedParameters = [];
                  this.diagMultiChange = false;
              }
            });
          } else {
            setOpcValueToast(null, this.$root);
            this.selectedParameters = [];
            this.diagMultiChange = false;
          }
        }
      } else {
        this.$toast.add({
            severity: 'warn',
            summary: 'Keine Änderungen!',
            detail: 'Es wurde kein einziger Wert geändert!',
            life: 3000,
        });
        this.selectedParameters = [];
        this.diagMultiChange = false;
      }
    },
    editEntry(ioData) {
      // this.ioDialogData = { ...ioData };
      // this.getUserList();
      const _obj = JSON.parse(JSON.stringify(ioData));
      for (let i = 0; i < _obj.length; i++) {
        this.ioObj[i] = store.getters.getValue(_obj[i].id);
      }
      // this.ioObj = store.getters.getValue(_obj.data.id);
      // const _obj = { ...ioData };
      this.ioDialogData = { ...this.content };
      // this.selectedKey[_obj.data.id] = true;

      console.log(this.ioDialogData);
      console.log(this.ioObj);

      // this.expandedKeys[this.nodes[0].key] = true;

      this.entryDialog = true;
      // this.intval_Value = setInterval(this.updateOpenValue, 1000);
    },
    changeVal(ioData) {
      this.entryDialog = false;
      this.submitted = false;
      this.$emit("entrySave", ioData);
      this.nodes = [];
      // this.getNodes();
      this.ioDialogData = ioData;
    },
    duplicateEntry() {
      this.$emit("entryDuplicate", this.content);
      // this.resetDialogData();
    },
    deleteEntry() {
      this.$emit("entryDelete", this.content);
      // this.resetDialogData();
    },
    getNodes() {
      // let arr = [];
      if (this.dataObj !== undefined) {
        this.nodes = [];
        this.dataObj.forEach((element) => {
          // store.dispatch("getValues", element.id);
          this.nodes.push(store.getters.getValue(element.id));
          // arr.push(store.dispatch("getValues", element.id));
          // console.log("Registered node: ", store.getters.getValue(element.id));
        });
      } else return undefined;
    },
    getValue(_id) {
      var index = this.valueObj.findIndex((x) => x._id === _id);
      if (index === -1) {
        // this.values.push(vars);
        console.log("NOT FOUND");
      } else {
        // this.values[index] = vars;
        return this.valueObj[index];
      }
    },
    getParameterConfigIO() {
      const response = [...this.nodeEntries];
      if (this.config_io !== null) {
        this.config_io = response;
        const _cat = helpers.findIndexByName(this.config_io.types_parameters, this.ioDialogData.data[0].category);
        console.log(this.config_io.types_parameters[_cat]);
      }
      // SocketioService.getServerData("config", 'io', (err, response) => {
      //   this.config_io = response;
      //   const _cat = helpers.findIndexByName(this.config_io.types_parameters, this.ioDialogData.data[0].category);
      //   console.log(this.config_io.types_parameters[_cat]);
      // });
    },
    filterParameters() {
      this.loading = true;
      // this.paramFiltered = [];
      // SocketioService.getServerData("status", "io", (err, response) => {
      //   if (!err) {
      //     const _obj = JSON.parse(JSON.stringify(response));
      //     let result = null;
      //     result = Object.entries(_obj)
      //       .filter(([, value]) => value.metadata.category === this.ioDialogData.data[0].category)
      //       .map(([, value]) => value);

      //     this.paramFiltered = [];
      //     result.forEach((element) => {
      //       if (Array.isArray(element.children)) {
      //         element.children.forEach((subElement, index) => {
      //           if (subElement.displayName === this.ioDialogData.data[0].children.key) {
      //             this.paramEntry.label = subElement.label;
      //             this.paramEntry.unit = subElement.unit;
      //             subElement._id = element._id;
      //             subElement.index = index;
      //             subElement.metadata = element.metadata;
      //             // console.log(subElement);
      //             this.paramFiltered.push(subElement);
      //           }
      //         })
      //       }
      //     });
      //     this.paramFiltered.sort((a, b) => a.metadata.department.localeCompare(b.metadata.department));
      //     this.loading = false;
      //   }
      //   // this.loading = false;
      // });
      this.paramFiltered = [];
      const node = [...this.nodeEntries];
      node.forEach((element) => {
        if (element) {
          if (Array.isArray(element.children)) {
            element.children.forEach((subElement, index) => {
              if (subElement !== null) {
                // if (subElement.displayName === this.ioDialogData.data[0].children.key) {
                if (subElement.parameter === this.ioDialogData.data[0].children.key) {
                  let filteredSubElement = {};
                  this.paramEntry.label = subElement.label;
                  this.paramEntry.unit = subElement.unit;
                  filteredSubElement = {...subElement};
                  filteredSubElement._id = element._id;
                  filteredSubElement.index = index;
                  filteredSubElement.metadata = element.metadata;
                  // console.log(subElement);
                  this.paramFiltered.push(filteredSubElement);
                }
              }
            })
          }
        }
      });
      this.paramFiltered.sort((a, b) => a.metadata.department.localeCompare(b.metadata.department));
      this.loading = false;
    },
    focusElement2(element) {
      // console.log(element);
      this.$refs[element].$el.focus();
    },
    focusElement(event) {
      if (event !== undefined) {
        event.target.select();
      }
    },
    focusField(name) {
      this.editField = name;
      this.$nextTick(() => this.$nextTick(() => this.focusElement(name)));
    },
    blurField() {
      this.editField = '';
    },
    showField(name) {
      return (this.editField == name)
    },
    multiParamChange() {
      // console.log(this.selectedParameters);
      const _obj = JSON.parse(JSON.stringify(this.selectedParameters));
      console.log(_obj);
      if (Array.isArray(_obj) && _obj.length > 0) {
        if (_obj[0].writeable === "true") {
          this.changingParameters = _obj;
          this.changingValue = helpers.paramValue(this.changingParameters[0], 'value');
          this.changingUnit = helpers.paramValue(this.changingParameters[0], 'unit');
          this.diagMultiChange = true;
        } else {
          this.$toast.add({ severity: 'warn', summary: 'Wert(e) nicht änderbar!', detail: 'Es handelt sich um keinen Einstellwert.', life: 3000 });
        }
      }
    },
    paramValue(paramEntry, mode) {
      return helpers.paramValue(paramEntry, mode);
    },
    // titleName: function () {
    //   if (this.dragObj.label === "") {
    //     return "";
    //   } else {
    //     return this.dragObj.label + ' ⚡';
    //   }
    // },
    // paramLabel: function () {
    //   if (this.paramEntry.label !== undefined) {
    //     return this.paramEntry.label;
    //   } else {
    //     return "!!!";
    //   }
    // },
    paramValueStr(paramEntry) {
      if (paramEntry !== undefined) {
        if (paramEntry.value !== undefined && paramEntry.unit !== undefined) {
          // if (paramEntry.unit === '#BOOL') {
          //   if (paramEntry.value === true) {
          //     return '🟢 Ein';
          //   } else {
          //     return '🔴 Aus';
          //   }
          // } else if (paramEntry.unit === '#HMS') {
          //   return helpers.hms(paramEntry.value, false);
          // } else {
          //   if (paramEntry.factor > 1) {
          //     return (paramEntry.value/paramEntry.factor).toFixed(1);
          //   } else {
          //     return paramEntry.value;
          //   }
          // }
          return paramEntry.value;
        } else {
          return "!!!";
        }
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.fade-in-enter-active {
  animation: fadeIn linear 0.3s;
}
@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}
@keyframes fadeOut {
  0% {opacity:1;}
  100% {opacity:0;}
}
.bounce-enter-active {
    animation: bounce-in 0.5s;
    // transition: opacity .5s;
}
.bounce-leave-active {
    animation: bounce-out 0.5s;
    // transition: opacity .5s;
}
.slide-fade-enter-active {
    transition: all 0.5s ease;
}
.slide-fade-leave-active {
    transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for <2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
}
.pulse-enter-active {
    animation: pulse 1s;
}
.pulse-leave-active {
    animation: pulse 0.5s;
}
@keyframes pulse {
    0% {
        // transform: scale(1); /* scaling to 0 */
        opacity: 0.8;
        // color: yellowgreen;
        // transform-origin: center center;
        // transform: scaleY(.9);
        text-shadow: black 2px 0 10px;
        transform: scale(0.9); /* increasing the size */
    }
    30% {
        transform: scale(1.1); /* increasing the size */
        text-shadow: gray 1px 0 10px;
    }
    100% {
        transform: scale(1); /* seeting back to initial size */
        opacity: 1;
    }
}
.grid {
    margin: 0 -0.5em 0 -0.5em !important;
}
.col {
    // line-height: 1.4em !important;
    margin: 0 !important;
    padding: 0 !important;
}
.p-divider {
    margin: 0 !important;
    padding: 0 !important;
}
.box {
    padding: 0.1em !important;
    text-align: center;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    // text-shadow: 1px 1px 1px black;
}
.box p {
    overflow-wrap: break-word;
    hyphens: auto;
}
.card {
    font-family: 'SensorFont';
    line-height: 1.3rem;
    position: relative;
    height: 100%;
    padding: 0.3em;
}
.text-normal {
    line-height: 0;
}
.menu-overlay {
    top: 0px;
    right: 0px;
    position: absolute;
    height: 100px;
    opacity: 0;
    transition: 0.2s ease;
    background: none;
    text-align: right;
}
.card:hover .menu-overlay {
    opacity: 1;
}
.menu-overlay .p-button-rounded {
    width: 2rem;
    height: 2rem;
}
// .list-enter-active,
// .list-leave-active {
//   transition: all 0.5s ease;
//   transform: scale(0.7); /* increasing the size */
// }
// .list-enter-from,
// .list-leave-to {
//   opacity: 0;
//   transform: scale(1); /* increasing the size */
// }
.list-enter-active {
    animation: list 1s;
}
.list-leave-active {
    animation: list 0.5s;
}
@keyframes list {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}


</style>